import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App.jsx";

const elm = document.querySelector("#root");
const root = createRoot(elm);

root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
