import React from "react";
import athena from './athena1.jpg';

const fetchData = async()=> {
		const res = await fetch('https://hello-world-icy-grass-102e.codycody8007.workers.dev/');
		const json= await res.json();
		return json;
	}

export default function App() {
	const [res,setRes] = React.useState(null);


	React.useEffect(()=> {
		fetchData().then(data=> setRes(data)).catch(err => console.log(err))
	},[]);

	return (
		<div>
			<h1>Simple Website</h1>
			<img src={athena} alt='athena' width={400} />
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex eligendi assumenda, iste consequatur hic itaque explicabo asperiores temporibus quod aliquid cumque. Neque, aspernatur deserunt. Maiores sit aliquid accusamus non dignissimos?</p>
			{res && <p>{JSON.stringify(res)}</p>}
		</div>
	);
}
